@mixin modal-colors($bg-color, $color) {
    .modal-content {
        background-color: $bg-color;
        color: $color;
    }

    .modal-body p {
        color: rgba($white, 0.8);
    }

    //inputs
    @include input-coloured-bg(
        $opacity-5,
        $white,
        $white,
        $transparent-bg,
        $opacity-1,
        $opacity-2
    );
}
