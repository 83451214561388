.alert {
    border: 0;
    color: $white;

    .alert-link {
        color: $white;
    }

    &.alert-success {
        background-color: darken($success, 10%);
    }

    i.fa,
    i.tim-icons {
        font-size: $font-paragraph;
    }

    .close {
        color: $white;
        opacity: 0.9;
        text-shadow: none;
        line-height: 0;
        outline: 0;
        &::after {
            display: inline-block;
            font: normal normal normal 1em/1 'Nucleo';
            vertical-align: middle;
            speak: none;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            font-size: 1rem;
            content: '\ea48';
        }
        span {
            display: none !important;
        }
    }

    span[data-notify='icon'] {
        font-size: 22px;
        display: block;
        left: 19px;
        position: absolute;
        top: 50%;
        margin-top: -11px;
    }

    button.close {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -13px;
        width: 25px;
        height: 25px;
        padding: 3px;
    }

    .close ~ span {
        display: block;
        max-width: 89%;
    }

    &.alert-with-icon {
        padding-left: 65px;
    }
}

.alert-dismissible {
    padding-right: 1.25rem;
    .close {
        &:hover,
        &:focus {
            color: rgba($white, 0.9);
            opacity: 1 !important;
        }

        @include media-breakpoint-down(xs) {
            top: 1rem;
            right: 0.5rem;
        }

        & > span:not(.sr-only) {
            font-size: 1.5rem;
            background-color: transparent;
            color: rgba($white, 0.6);
        }

        &:hover,
        &:focus {
            & > span:not(.sr-only) {
                background-color: transparent;
                color: rgba($white, 0.9);
            }
        }
    }
    &.animated {
        z-index: 9999;
        .close {
            right: 10px;
        }
    }
}
