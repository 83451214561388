/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.tim-row {
    margin-bottom: 20px;
}

.tim-white-buttons {
    background-color: #777777;
}

.typography-line {
    padding-left: 25%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
}

.typography-line span {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    position: absolute;
    width: 260px;
    text-transform: none;
}

.tim-row {
    padding-top: 60px;
}

.tim-row h3 {
    margin-top: 0;
}

.offline-doc .page-header {
    display: flex;
    align-items: center;
}

.offline-doc .footer {
    position: absolute;
    width: 100%;
    background: transparent;
    bottom: 0;
    color: #fff;
    z-index: 1;
    padding-left: 0;
}

@media all and (min-width: 992px) {
    .sidebar .nav > li.active-pro {
        position: absolute;
        width: 100%;
        bottom: 10px;
    }
}

.card.card-upgrade .card-category {
    max-width: 530px;
    margin: 0 auto;
}

.offline-doc .page-header {
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    color: #ffffff;
    position: relative;
}
.offline-doc .page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.offline-doc .page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
}
.offline-doc .page-header footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.offline-doc .page-header .container {
    height: 100%;
    z-index: 1;
}
.offline-doc .page-header .category,
.offline-doc .page-header .description {
    color: rgba(255, 255, 255, 0.8);
}
.offline-doc .page-header.page-header-small {
    min-height: 60vh;
    max-height: 440px;
}
.offline-doc .page-header.page-header-mini {
    min-height: 40vh;
    max-height: 340px;
}
.offline-doc .page-header .title {
    margin-bottom: 15px;
}
.offline-doc .page-header .title + h4 {
    margin-top: 10px;
}
.offline-doc .page-header:after,
.offline-doc .page-header:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: '';
}
.offline-doc .page-header:before {
    background-color: rgba(0, 0, 0, 0.8);
}
