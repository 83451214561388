.form-check {
    margin-top: 0.5rem;
    padding-left: 0;
}

.form-check .form-check-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 25px;
    line-height: 18px;
    margin-bottom: 0;
    -webkit-transition: color 0.3s linear;
    -moz-transition: color 0.3s linear;
    -o-transition: color 0.3s linear;
    -ms-transition: color 0.3s linear;
    transition: color 0.3s linear;
}
.radio .form-check-sign {
    padding-left: 28px;
}

.form-check-radio.form-check-inline .form-check-label {
    padding-left: 5px;
    margin-right: 10px;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
    content: ' ';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    cursor: pointer;
    border-radius: 3px;
    top: 0;
    border: 1px solid darken($dark-gray, 10%);
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check input[type='checkbox']:checked + .form-check-sign::before,
.form-check input[type='checkbox']:checked + .form-check-sign::before {
    border: none;
    background-color: $primary;
}

.form-check .form-check-sign::after {
    font-family: 'nucleo';
    content: '\ea1b';
    top: 0px;
    text-align: center;
    font-size: 14px;
    opacity: 0;
    color: $white;
    font-weight: $font-weight-bold;
    border: 0;
    background-color: inherit;
}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {
    color: $dark-gray;
    opacity: 0.5;
    cursor: not-allowed;
}

.form-check input[type='checkbox'],
.radio input[type='radio'] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
}
.form-check input[type='checkbox']:checked + .form-check-sign::after {
    opacity: 1;
    font-size: 10px;
    margin-top: 0;
}

.form-check input[type='checkbox'] + .form-check-sign::after {
    opacity: 0;
    font-size: 10px;
    margin-top: 0;
}

.form-control input[type='checkbox']:disabled + .form-check-sign::before,
.checkbox input[type='checkbox']:disabled + .form-check-sign::after {
    cursor: not-allowed;
}

.form-check input[type='checkbox']:disabled + .form-check-sign,
.form-check input[type='radio']:disabled + .form-check-sign {
    pointer-events: none;
}

.form-check-radio .form-check-label {
    padding-top: 3px;
}
.form-check-radio .form-check-sign::before,
.form-check-radio .form-check-sign::after {
    content: ' ';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid darken($dark-gray, 10%);
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 3px;
    padding: 1px;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check-radio input[type='radio'] + .form-check-sign:after,
.form-check-radio input[type='radio'] {
    opacity: 0;
}
.form-check-radio input[type='radio']:checked + .form-check-sign::after {
    width: 6px;
    height: 6px;
    background-color: $primary;
    border-color: $primary;
    top: 9px;
    left: 6px;
    opacity: 1;
}

.form-check-radio input[type='radio']:checked + .form-check-sign::before {
    border-color: $primary;
}

.form-check-radio input[type='radio']:checked + .form-check-sign::after {
    opacity: 1;
}

.form-check-radio input[type='radio']:disabled + .form-check-sign {
    color: $dark-gray;
}

.form-check-radio input[type='radio']:disabled + .form-check-sign::before,
.form-check-radio input[type='radio']:disabled + .form-check-sign::after {
    color: $dark-gray;
}
